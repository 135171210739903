<template>
  <div>
    <div class="wrap user_set bgw">
      <div v-show="navTab <3" class="nav">
        <div @click=" navTab1()" :class="{navActive: navTab == 0}">
          个人资料
          <i></i>
        </div>
        <div @click=" navTab2()" :class="{navActive: navTab == 1}">
          修改密码
          <i></i>
        </div>
<!--        <div @click=" navTab3()" :class="{navActive: navTab == 2}">
          支付密码
          <i></i>
        </div> -->
      </div>
      <!-- 修改 个人信息  -->
      <div v-show="navTab == 0" class="content">
        <div class="user_name">
          <span>昵称:</span>
          <input maxlength="12" type="text" v-model="name" placeholder="用户昵称" />
        </div>
        <div class="user_img">
          <span>头像</span>
          <div>
            <el-upload
              :action="imageUrl"
              list-type="picture-card"
              :on-success="UserImgSuccess"
              :before-upload="beforeAvatarUpload"
              :on-remove="UserImgtRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="user_birthday">
          <span>出生日期</span>
          <el-date-picker
            v-model="birthday"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="user_sex">
          <span>性别</span>
          <el-radio v-model="sex" label="1">男性</el-radio>
          <el-radio v-model="sex" label="2">女性</el-radio>
        </div>
        <button @click="UserDataChecking()">保存</button>
      </div>

      <!-- 修改 密码 -->
      <div v-show="navTab == 1" class="content user_set_phone">
        <div>
          <span>手机号</span>
          <input v-model="phone" type="text " maxlength="11" />
        </div>
        <div class="checking">
          <span>验证码</span>
          <input v-model="phoneCode" type="text " maxlength="6" />
          <p @click="phoneChecking" v-if="time == 60 ">获取验证</p>
          <p v-if="time != 60 ">{{time}}</p>
        </div>
        <div>
          <span>输入新密码</span>
          <input v-model="password1" maxlength="12" type="password " />
        </div>
        <div>
          <span>再次输入新密码</span>
          <input v-model="password2" maxlength="12" type="password " />
        </div>
        <button @click="UserPasswordChecking()">保存</button>
      </div>
      <!-- 支付密码 -->
      <div v-show="navTab == 2" class="content user_set_phone">
        <div>
          <span>手机号</span>
          <input v-model="z_phone" type="text " maxlength="11" />
        </div>
        <div class="checking">
          <span>验证码</span>
          <input v-model="z_phoneCode" type="text " maxlength="6" />
          <p @click="phoneChecking" v-if="z_time == 60 ">获取验证</p>
          <p v-if="z_time != 60 ">{{z_time}}</p>
        </div>
        <div>
          <span>输入新密码</span>
          <input v-model="z_password1" maxlength="12" type="password " />
        </div>
        <div>
          <span>再次输入新密码</span>
          <input v-model="z_password2" maxlength="12" type="password " />
        </div>
        <button @click="UserPasswordChecking()">保存</button>
      </div>
    </div>
  </div>
</template>
<script>
import { sendSms } from "@/request/public"; //手机验证
import { updateinfo } from "@/request/user"; // 修改个人信息
import { modifypassword } from "@/request/user"; //修改密码
import { modifyMobile } from "@/request/user";
import { usergetinfo } from "@/request/user";

export default {
  props: ["navTab"],
  data() {
    return {
      birthday: "", // 出生日期
      sex: 0, //性别
      checking1: "", //验证
      time: 60, //倒计时
      z_time: 60,
      phone: "", //手机号
      phoneCode: "", //手机号验证码
      password1: "", //新密码
      password2: "", // 再次 输入 新密码
      z_phone: "", //手机号
      z_phoneCode: "", //手机号验证码
      z_password1: "", //新密码
      z_password2: "", // 再次 输入 新密码
      password3: "", //登录密码
      name: "", // 个人信息  名字
      avatar: "", // 头像
    };
  },
  created() {
    //   个人信息
      usergetinfo({
        token: this.$token
      }).then(res => {
        this.userList = res.data;
        this.sex = this.userList.sex;
        this.name = this.userList.user_nickname;
        this.birthday = this.userList.birthday;
      });
  },
  methods: {
    UserPhoneChecking() {
      if (this.navTab == 1) {
        if (this.phone == "") {
          this.$message.error("手机号不能为空");
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.$message.error("手机号格式不正确");
          return false;
        } else if (this.phoneCode == "") {
          this.$message.error("验证码不能为空");
          return false;
        } else {
          this.UserPhoneSend();
        }
      } else {
        if (this.z_phone == "") {
          this.$message.error("手机号不能为空");
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.z_phone)) {
          this.$message.error("手机号格式不正确");
          return false;
        } else if (this.z_phoneCode == "") {
          this.$message.error("验证码不能为空");
          return false;
        } else {
          this.UserPhoneSend();
        }
      }
    },
    UserPhoneSend() {
      modifyMobile({
        token: this.$token,
        new_mobile: this.phone,
        code: this.phoneCode,
        user_pass: this.password3
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.$emit("changeState", 0);
        }
      });
    },
    UserPasswordChecking() {
      if (this.navTab == 1) {
        if (this.password1 == "") {
          this.$message.error("密码 不能 为空");
          return;
        } else if (this.password1.length < 6) {
          this.$message.error("密码 不能 低于 6位");
          return;
        } else if (this.password1 != this.password2) {
          this.$message.error("密码 不一致");
          return;
        } else {
          this.UserPasswordSend();
        }
      } else if (this.navTab == 2) {
        if (this.z_password1 == "") {
          this.$message.error("密码 不能 为空");
          return;
        } else if (this.z_password1.length < 6) {
          this.$message.error("密码 不能 低于 6位");
          return;
        } else if (this.z_password1 != this.z_password2) {
          this.$message.error("密码 不一致");
          return;
        } else {
          this.UserPasswordSend();
        }
      }
    },
    UserPasswordSend() {
      if (this.navTab == 1) {
        modifypassword({
          token: this.$token,
          code: this.phoneCode,
          password: this.password1,
          repassword: this.password2,
          type: 0
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
            this.$emit("changeState", 0);
          }
        });
      } else {
        modifypassword({
          token: this.$token,
          code: this.z_phoneCode,
          password: this.z_password1,
          repassword: this.z_password2,
          type: 1
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
            this.$emit("changeState", 0);
          }
        });
      }
    },
    UserDataChecking() {
      if (this.name == "") {
        this.$message.error("姓名 不能 为空");
        return;
      } else if (this.avatar == "") {
        this.$message.error("头像 不能 为空");
      } else if (this.birthday == "") {
        this.$message.error("出生日期 不能 为空");
      } else if (this.sex == "") {
        this.$message.error("性别 不能 为空");
      } else {
        this.UserDataSend();
      }
    },
    UserDataSend() {
      updateinfo({
        token: this.$token,
        user_nickname: this.name,
        avatar: this.avatar,
        birthday: this.birthday,
        sex: this.sex
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.$emit("changeState", 0);
        }
      });
    },
    //  个人 头像
    UserImgSuccess(response) {
      // let imgurl = response.data.file.url;
      // file.url = imgurl;
      this.avatar = response.data.file.url;
    },
    // 个人头像 删除
    UserImgtRemove() {
      this.avatar = "";
    },
    navTab1() {
      this.$emit("change", 0);
    },
    navTab2() {
      this.$emit("change", 1);
    },
    navTab3() {
      this.$emit("change", 2);
    },
    // 手机号验证
    phoneChecking() {
      if (this.navTab == 1) {
        if (this.phone == "") {
          this.$message.error("手机号不能为空");
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.$message.error("手机号格式不正确");
          return false;
        } else if (this.time == 60) {
          this.time = 59;
          this.times();
          sendSms({
            account: this.phone
          }).then(res => {
            if (res.code == 1) {
              this.checking1 = 2;
              this.$message({
                message: "发送成功",
                type: "success"
              });
            } else {
              this.$message.error("手机号格式不正确");
            }
          });
        }
      } else if(this.navTab == 2){
          if (this.z_phone == "") {
          this.$message.error("手机号不能为空");
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.z_phone)) {
          this.$message.error("手机号格式不正确");
          return false;
        } else if (this.z_time == 60) {
          this.z_time = 59;
          this.z_times();
          sendSms({
            account: this.z_phone
          }).then(res => {
            if (res.code == 1) {
              this.checking1 = 2;
              this.$message({
                message: "发送成功",
                type: "success"
              });
            } else {
              this.$message.error("手机号格式不正确");
            }
          });
        }
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    times() {
      var Athis = this;
      var setTime = setTimeout(function() {
        if (Athis.time == 0) {
          clearTimeout(setTime);
          Athis.time = 60;
        } else {
          Athis.time--;
          Athis.times();
        }
      }, 1000);
    },
    z_times() {
      var Athis = this;
      var setTime = setTimeout(function() {
        if (Athis.z_time == 0) {
          clearTimeout(setTime);
          Athis.z_time = 60;
        } else {
          Athis.z_time--;
          Athis.z_times();
        }
      }, 1000);
    }
  }
};
</script>
<style lang="less" >
.user_set {
  .checking {
    input {
      width: 237px !important;
    }
    p {
      cursor: Default;
      margin-left: 16px;
      display: inline-block;
      width: 160px;
      height: 46px;
      color: #808080;
      line-height: 46px;
      font-size: 16px;
      text-align: center;
      background: rgba(230, 230, 230, 1);
    }
  }
}
// 修改密码
.user_set_phone {
  span {
    width: 130px !important;
  }
  input {
    width: 380px;
    height: 46px;
    text-indent: 10px;
    border: 1px solid #e6e6e6 !important;
  }
}

// 个人资料
.user_set {
  min-height: 500px;
  padding-bottom: 40px;
  button {
    width: 130px;
    height: 46px;
    font-size: 18px;
  }
}
.user_set .user_sex {
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
}
.user_set .user_birthday {
  .el-input__inner {
    width: 220px;
    height: 46px;
    line-height: 46px;
    border: 1px solid #e6e6e6 !important;
    font-size: 16px;
  }
  .el-input__icon {
    line-height: 48px;
  }
}
.user_set .user_img {
  height: 150px !important;
  span {
    position: absolute;
    left: 0;
    top: 0;
  }
  > div {
    // // margin-left: 90px;
    // padding-left: 90px;
    overflow: hidden;
    height: 150px;
    position: absolute;
    left: 90px;
    width: 150px;
  }
  p {
    position: absolute;
    left: 90px;
    width: 186px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    color: white;
  }
}
.user_set .user_name {
  input {
    width: 380px;
    height: 46px;
    text-indent: 10px;
    border: 1px solid #e6e6e6 !important;
  }
}
.user_set .content {
  padding-top: 46px;
  padding-left: 72px;
  color: #4d4d4d;
  font-size: 16px;
  > div {
    height: 46px;
    position: relative;
    margin-bottom: 36px;
  }
  > div > span {
    display: inline-block;
    width: 90px;
  }
}
.user_set .user_num {
  line-height: 46px;
}
.user_set .nav {
  height: 68px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
  > div {
    line-height: 68px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    cursor: Pointer;
  }
}
.user_set .navActive {
  color: #4eeec8;

  i {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #4eeec8;
  }
}
.user_set {
  border: 1px solid #e6e6e6;
}
.el-input__inner {
  font-size: 14px;
}
</style>