<template>
  <div class="wrap bgw">
    <div class="content">
      <div class="fl">
        <div class="top">
          <img src="@/assets/image/logo_shape@2x.png" alt />联众医药
          <p>山东联众医药连锁</p>
        </div>
        <div class="phone">
          联系电话:
          <span>012345678910</span>
        </div>
      </div>
      <div class="fr">
        <div class="top"></div>
        <div class="txt"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.content {
  height: 967px;
  margin-top: 20px;
  > .fl {
    width: 260px;
    height: 967px;
    .phone {
      padding-top: 27px;
      text-align: center;
      font-size: 20px;
      span {
        color: #4c94ff;
      }
    }
    .top {
      height: 180px;
      background: #4eeec8;
      font-size: 20px;
      text-align: center;
      padding-top: 30px;
    }
    p {
      margin-top: 44px;
      color: white;
    }
    img {
      width: 50px;
      height: 30px;
      margin-right: 10px;
      // vertical-align: middle;
    }
  }
  > .fr {
    width: 938px;
    height: 967px;
    border-left: 1px solid #e6e6e6;
    .top {
      height: 652px;
      border-bottom: 1px solid #e6e6e6;
    }
    .txt {
      height: 340px;
    }
  }
}
</style>