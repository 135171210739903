<template>
  <div>
    <div class="indexUser">
      <div class="bg1 userBox">
        <div v-if="changeWalletState == 0" class="wrap bgw">
          <div class="nav">
            <div>
              <p>{{Data.balance}}</p>
              <p>余额</p>
              <p>
                <span @click="navtab1()">提现</span>
                <span @click="navtab()">充值</span>
              </p>
            </div>
            <div v-if="userList.bank_info">
              <p v-if="!userList.bank_info.alipay">******</p>
              <p v-else>{{formatNumber(userList.bank_info.alipay.account)}}</p>
              <p>支付宝</p>
              <p @click="navtab2()" class="cursor">设置</p>
            </div>
            <div v-if="userList.bank_info">
              <p v-if="!userList.bank_info.wxpay">******</p>
              <p v-else>{{formatNumber(userList.bank_info.wxpay.account)}}</p>
              <p>微信</p>
              <p @click="navtab3()" class="cursor">设置</p>
            </div>
            <div>
              <p>{{cardArr.length}}</p>
              <p>银行卡</p>
              <p @click="navtab4()" class="cursor">查看</p>
            </div>
          </div>
          <div class="title">
            余额变动记录
            <i></i>
          </div>
          <div class="list">
            <div class="top">
              <p class="one">日期</p>
              <p class="one">余额变化</p>
              <p class="one">详细说明</p>
            </div>
            <div v-for="(v,i) in list" :key="i">
              <p class="one">{{v.add_time}}</p>
              <p :class="{one: true, rise: v.money > 0, fall: v.money < 0 }">{{ v.money > 0 ?  "+" : "" }} {{v.money}}</p>
              <p class="one fll">{{v.note}}</p>
            </div>
          </div>
        </div>
        <!-- 充值 -->
        <recharge :userList="userList" v-if="changeWalletState == 1"></recharge>
        <!-- 提现 -->
        <cash :userList="userList" v-if="changeWalletState == 2"></cash>
        <!-- 支付宝 -->
        <zhifubao @index="index" v-if="changeWalletState == 3"></zhifubao>
        <!-- 微信 -->
        <weixin @index="index" v-if="changeWalletState == 4"></weixin>
        <!-- 银行卡 -->
        <card @index="index" v-if="changeWalletState == 5" :cardArrs="cardArr"></card>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import publicBottom from "@/components/public/public_bottom"; //底部

import recharge from "./Recharge"; //充值
import cash from "./cash"; // 提现
import zhifubao from "./zhifubao"; // 支付宝
import weixin from "./weixin"; //微信
import card from "./card"; // 银行卡
import { mapState, mapMutations } from "vuex";
import { getListCard } from "@/request/user"; // 新增 银行卡
import { yuebiandong } from "@/request/index"; // 余额变动
import { usergetinfo } from "@/request/user";

export default {
  components: {
    publicBottom,
    recharge,
    cash,
    zhifubao,
    weixin,
    card
  },
  data() {
    return {
      Data: [],
      list: [],
      cardArr: [],
      userList: [], // 个人信息
      changeWalletState: 0
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.starts();
    this.start();
  },
  methods: {
    starts() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.Data = res.data;
      });
      getListCard({
        //银行卡信息
        token: this.$tokens
      }).then(res => {
        this.cardArr = res.data;
      });
      // 余额变动
      yuebiandong({
        token: this.$tokens
      }).then(res => {
        this.list = res.data.data;
        // for (let i = 0; i < this.list.length; i++) {
        //   let time = this.list[i].add_time;
        //   time = time * 1000;
        //   var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        //   var Y = date.getFullYear() + "-";
        //   var M =
        //     (date.getMonth() + 1 < 10
        //       ? "0" + (date.getMonth() + 1)
        //       : date.getMonth() + 1) + "-";

        //   var D =
        //     (date.getDate() + 1 < 10
        //       ? "0" + (date.getDate() + 1)
        //       : date.getDate() + 1) + "/";
        //   var h = date.getHours() + ":";
        //   var m = date.getMinutes() + ":";
        //   var s = date.getSeconds();
        //   let times = Y + M + D + h + m + s;
        //   // times = times.substr(0, 10);
        //   this.list[i].add_time = times;
        // }
      });
    },
    formatNumber(num) {
      num = num.toString();
      return "**" + num.slice(num.length - 4);
    },
    index(num) {
      if(num == 0) this.changeWalletState = 0;
      this.setWalletState(0);
      this.starts();
    },
    navtab() {
      this.changeWalletState = 1;
    },
    navtab1() {
      this.changeWalletState = 2;
    },
    navtab2() {
      this.changeWalletState = 3;
    },
    navtab3() {
      this.changeWalletState = 4;
    },
    navtab4() {
      this.changeWalletState = 5;
    },
    start() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
    },
    ...mapMutations(["setWalletState"])
  }
};
</script>

<style lang="less" scoped>
.list {
  min-height: 400px;
  > div {
    height: 69px;
    line-height: 69px;
    font-size: 16px;
    color: #4d4d4d;
    display: flex;
  }
  .top {
    padding-top: 20px;
  }
  .top p {
    height: 28px;
    line-height: 28px;
    border-left: 1px solid #dbdbdb;
  }
  > div:nth-child(odd) {
    background: #f8f8f8;
  }

  p {
    width: 40%;
    text-align: center;
  }
  .fll {
    text-indent: 62px;
    text-align: left;
  }
  .rise {
    color: #4eeec8;
  }
  .fall {
    color: #ff6a21;
  }
}
.title {
  height: 68px;
  line-height: 68px;
  color: #4eeec8;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  padding-left: 50px;
  i {
    position: absolute;
    bottom: 0;
    left: 30px;
    height: 1px;
    width: 146px;
    border-bottom: 2px solid #4eeec8;
  }
}
.nav {
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  > div {
    text-align: center;
    width: 50%;
    height: 240px;
    padding-top: 60px;
  }
  p {
    margin: auto;
    margin-bottom: 14px;
  }
  > div > p:nth-of-type(1) {
    font-size: 36px;
  }
  > div > p:nth-of-type(2) {
    font-size: 18px;
  }
  > div > p:nth-of-type(3) {
    font-size: 18px;
    color: #4eeec8;
  }
  span {
    display: inline-block;
    width: 80px;
    text-align: center;
    cursor: pointer;
  }
  .cursor {
    cursor: pointer;
  }
}
</style>