// 系统消息

<template>
  <div class="message">
    <div class="fl">
      <div class="title">
        <img src="@/assets/image/news_system_icon@2x.png" alt />
        <span>系统消息</span>
      </div>
    </div>
    <div class="fr">
      <p class="time">2019-12-9 12:12:12</p>
      <div class="c_content" v-for="(item, index) in messages" :key="index">
        <p>{{item.title}}</p>
        <p class="content">{{item.content}}</p>
      </div>
      <p>没有更多消息</p>
    </div>
  </div>
</template>

<script>
// 获取消息列表
import { getMessageList } from "@/request/personnel";
export default {
  data() {
    return {
      messages: []
    };
  },
  created() {
    getMessageList({
      token: this.$token,
      type: 0 // 0代表获取系统消息
    }).then(res => {
      this.messages = res.data.data;
      console.log(this.messages);
    });
  }
};
</script>

<style lang="less" scoped>
.message {
  width: 1202px;
  height: 567px;
  border: 1px solid #e6e6e6;
  margin: 0px auto 30px;
  background: #fff;
}
.fl {
  width: 260px;
  height: 567px;
  background-color: #65f8d5;
  .title {
    height: 80px;
    background: rgba(255, 255, 255, 0.5);
    img {
      margin-top: 15px;
      margin-left: 15px;
      vertical-align: -15px;
    }
    span {
      color: #313131;
      margin-left: 15px;
    }
  }
}
.fr {
  width: 940px;
  height: 567px;
  overflow: auto;
  > p {
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #616161;
  }
  .c_content {
    width: 660px;
    margin: 0 auto;
    background: #f5f5f5;
    p {
      padding: 0 20px;
    }
    p:nth-child(1) {
      height: 65px;
      line-height: 65px;
      font-size: 24px;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
    }
    p:nth-child(2) {
      line-height: 65px;
    }
    .content {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #616161;
    }
  }
}
.fr::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
.fr::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  // background-color: #f5f5f5;
  background: #fff;
}

/*定义滑块 内阴影+圆角*/
.fr::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
</style>
