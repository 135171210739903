// 医生在线

<template>
  <div class="pre">
    <p>{{title}}</p>
    <div class="wrap">
      <div class="car">
        <p
          class="date"
        >{{new Date().getFullYear()}}年{{new Date().getMonth() + 1}}月{{new Date().getUTCDate()}}号</p>
        <p class="week">星期{{ getDay }}</p>
      </div>
      <div class="time">
        <div class="fl">
          <p>{{timeType}}</p>
          <img src="@/assets/image/yishengzaixan.png" alt="">
        </div>
        <div class="fr">
          <p>上午 : {{this.beforestart}} -{{this.beforeend}}</p>
          <p>下午 : {{this.afterstart}} - {{this.afterend}}</p>
        </div>
      </div>
     <!-- <div class="line">
        <span  class="fr on" type="primary" @click="online">上线</span>
        <span  class="fr down" type="primary" @click="downline">下线</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { setDuty } from "@/request/personnel";
import { getDutyStatus  } from "@/request/personnel"
export default {
  props: {
    title: {
      default: ""
    },
    timeType: {
      default: ""
    }
  },
  data() {
    return {
      is_duty: 0,
	  beforestart: "",
	  beforeend: "",
	  afterstart: "",
	  afterend: "",
    }
  },
  created() {
    getDutyStatus({token: this.$token}).then(res => {
      if(res.code == 1) {
        this.is_duty = res.data.is_duty
      }
    })
	this.getTime();
  },
  methods: {
	  getTime() {
	  	this.$axios.post("api/doctor/getDutyStatus", {
	  		token: this.$token
	  	}).then(res => {
	  		if (res.data.code == 1) {
	  			let text = res.data.data;
	  			this.beforestart = text.morning_time.slice(0, 5);
	  			this.beforeend = text.morning_time.slice(6);
	  			this.afterstart = text.afternoon_time.slice(0, 5);
	  			this.afterend = text.afternoon_time.slice(6);
	  		}
	  	})
	  },
    // 上线处理函数
    online() {
      setDuty({
        token: this.$token,
        is_duty: 1
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "你已上线"
          });
          this.is_duty = 1;
        }
      });
      // this.changePage();
    },
    // 下线处理函数
    downline() {
      setDuty({
        token: this.$token,
        is_duty: 0
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "你已下线"
          });
          this.is_duty = 0;
        }
      });
      // this.changePage();
    },
    changePage() {
      this.$emit("changeState", 0);
    }
  },
  computed: {
    getDay() {
      if (new Date().getDay() === 0) return "七";
      else if (new Date().getDay() === 1) return "一";
      else if (new Date().getDay() === 2) return "二";
      else if (new Date().getDay() === 3) return "三";
      else if (new Date().getDay() === 4) return "四";
      else if (new Date().getDay() === 5) return "五";
      else return "六";
    }
  }
};
</script>

<style lang="less" scoped>
.pre {
  width: 1200px;
  margin: 0 auto;
  height: 760px;
  padding-top: 40px;
  font-size: 16px;
}
.pre > p {
  font-size: 20px;
  color: #000;
  height: 70px;
  border: 1px solid #ccc;
  border-bottom: 0px;
  line-height: 70px;
  padding-left: 15px;
  background: #fff;
}
.wrap {
  border: 1px solid #ccc;
  padding-bottom: 150px;
  height: 600px;
  background: #fff;
}
.el-button {
  height: 22px;
  border: none;
  line-height: 22px;
  vertical-align: -10px;
}
.car,
.time {
  width: 440px;
  height: 210px;
  margin: 0 auto;
  text-align: center;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 25px;
  background-color: #c8fff2;
  .fr {
    width: 150px;
    text-align: left;
    p:nth-of-type(2) {
      margin-top: 20px;
    }
  }
  .fl {
    img {
      width: 50px;
      margin-top: 20px;
    }
  }
}
.car {
  background-color: #c8fff2;
  height: 200px;
  margin-top: 50px;
  overflow: hidden;
  .date {
    font-size: 25px;
    margin-top: 10px;
  }
  .week {
    font-size: 33px;
    margin-top: 30px;
  }
}
.line {
  padding-top: 30px;
  overflow: hidden;
  width: 400px;
  margin: 0 auto;
  span {
    font-weight: 500;
    padding: 10px 50px;
    margin-top: -5px;
    margin-right: 130px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .down {
    color: red;
  }
  .on {
    color: #4eeec8;
  }
}
.time {
  border-radius: 25px;
  padding-left: 50px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 30px;
  color: #4d4d4d;
  p {
    margin-top: 15px;
  }
}
</style>