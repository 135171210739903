<template>
  <div class="wrap bgw">
    <div class="top">
      <img class="ffl" :src="userList.avatar" alt />
      <p>
        提现账户：
        <span>{{userList.user_nickname}}</span>
      </p>
      <div class="fr">
        账户余额：
        <span>{{userList.balance}}</span>
      </div>
    </div>
    <div class="box">
      <div class="list">
        <span
          @click="moneyState = v"
          :class="{moneyActive : moneyState == v}"
          v-for="(v,i) in moneyList"
          :key="i"
        >￥ {{v}}</span>
      </div>
      <div class="list">
        <span @click="moneyState = -1" :class="{moneyActive : moneyState == -1}">其他金额</span>
      </div>
      <div class="type" v-if="userList.bank_info != '' ">
        <div class="fl">提现至:</div>
        <!-- 微信 -->
        <div
          @click="type ='wxpay'"
          v-if="userList.bank_info.wxpay != false "
          :class="{typeActive : type == 'wxpay'}"
          class="fl"
        >
          <span></span>
          <img src="@/assets/image/payment_weixin_img@2x.png" alt />
        </div>
        <!-- 支付宝 -->
        <div
          @click="type ='alipay'"
          v-if="userList.bank_info.alipay != false "
          :class="{typeActive : type == 'alipay'}"
          class="fl"
        >
          <span></span>
          <img src="@/assets/image/payment_zhifubao_img@2x.png" alt />
        </div>
        <!-- 银联  -->
        <div
          @click="type ='bankpay'"
          v-if="userList.bank_info.bank != false "
          :class="{typeActive : type == 'bankpay'}"
          class="fl"
        >
          <span></span>
          <img src="@/assets/image/payment_yinlian_img@2x.png" alt />
        </div>
        <div
          v-if="!userList.bank_info.bank && !userList.bank_info.alipay && !userList.bank_info.wxpay"
        >
          <p>暂无可提现账户！</p>
        </div>
      </div>
      <div class="type moneySum">
        <div class="fl fll">提现金额</div>
        <div v-if="moneyState != -1" class="fl m0">{{moneyState}} 元</div>
        <div v-if="moneyState == -1" class="fl m0">
          <input
            v-model="value"
            step="1"
            min="0"
            onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            type="number"
          /> 元
        </div>
      </div>
      <div class="type paycode">
        <div class="fl place">请输入支付密码</div>
        <div class="fl input">
          <input type="password" v-model="paycode" />
        </div>
      </div>
      <button @click="checkingSend()" v-if="userList.bank_info != '' ">确认提现</button>
    </div>
  </div>
</template>
<script>
import { withdrew } from "@/request/index";
import md5 from "js-md5";
export default {
  props: ["userList"],
  data() {
    return {
      moneyState: 10,
      value: 0,
      moneyList: [10, 20, 50, 100, 300],
      type: "",
      paycode: ""
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.checking();
  },
  methods: {
    send() {
      let money = 0;
      // let paycode = md5(this.paycode)
      if (this.moneyState != -1) {
        money = this.moneyState;
      } else {
        money = this.value;
      }
      if (this.type != "bankpay") {
        withdrew({
          token: this.$tokens,
          pay_type: this.type,
          pay_password: this.paycode,
          money: money,
          account: this.userList.bank_info[this.type].account,
          realname: this.userList.bank_info[this.type].realname
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "提现申请成功",
              type: "success"
            });
            let Athis = this;
            setTimeout(function() {
              Athis.$router.replace({ path: "/user/index" });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        withdrew({
          token: this.$tokens,
          pay_type: this.type,
          pay_password: this.paycode,
          money: money,
          account: this.userList.bank_info.bank.account,
          realname: this.userList.bank_info.bank.realname,
          bank_name: this.userList.bank_info.bank.bank_name
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "提现申请成功",
              type: "success"
            });
            let Athis = this;
            setTimeout(function() {
              Athis.$router.replace({ path: "/user/index" });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    checkingSend() {
      let money = 0;
      if (this.moneyState != -1) {
        money = this.moneyState;
      } else {
        money = this.value;
      }
      if (money < 0 || money == 0) {
        this.$message.error("提现金额请大于0元");
      } else if (this.type == "") {
        this.$message.error("请选择提现类型");
      } else {
        this.send();
      }
    },
    checking() {
      if (this.userList.bank_info != "") {
        console.log(this.userList.bank_info);
      } else {
        this.$message.error("当前没有可提现账户，请绑定后再来提现");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.m0 {
  padding: 0 !important;
}
input {
  width: 100px;
  text-align: center;
  font-size: 20px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
button {
  margin-top: 20px;
  width: 130px;
  height: 46px;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}
.moneySum {
  .fll {
  }
  .flll {
    color: #ff0303;
    padding-left: 0 !important;
  }
}
.typeActive {
  span {
    border: 1px solid #4eeec8 !important;
    background: #4eeec8;
  }
}
.moneyActive {
  border: 2px solid #4eeec8 !important;
}
.type {
  margin-top: 20px;
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  text-align: left;
  div:nth-of-type(1) {
    margin-left: 0;
    width: 80px;
    padding-left: 0;
  }
  > div {
    position: relative;
    padding-left: 40px;
    margin-right: 20px;
    // margin-right: 40px; border:1px solid red;
  }
  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 50%;
    transform: translate(0, -50%);
  }
  img {
    vertical-align: middle;
  }
}
.paycode {
  .place.place {
    width: 120px;
  }
  .input.input {
    padding-left: 10px;
    input {
      width: 130px;
      font-size: 30px;
      height: 25px;
    }
  }
}
.list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  span {
    width: 158px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    display: inline-block;
    border: 2px solid #bfbfbf;
  }
}
.box {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 40px;
}
.top {
  height: 182px;
  line-height: 182px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  border-bottom: 1px solid #e6e6e6;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    vertical-align: middle;
  }
  p {
    display: inline-block;
    margin-left: 36px;
  }
  span {
    color: #4eeec8;
  }
}
</style>