// 医生、药师、等工作人员的接口

import { post, get } from './http'

// 科室分类列表
export const getCategoryList = data => get('/api/doctor/getCategoryList', data)

// 获取科室所有分类
export const getAllCategoryList = data => get('/api/doctor/getAllCategoryList',data)

// 获取消息列表
export const getMessageList = data => get('/api/message/getList', data)

// 获取问诊拒绝原因
export const getReason = data => get("/api/inquiry/getReason", data)

// 设置医生工作状态
export const setDuty = data => post("/api/doctor/setDuty", data);

// 获取待接诊数量
export const getWaittingNum = data => get("/api/doctor/getWaittingNum", data);

// 设置选择的患者信息
export const setPatientInfo = data => post("/api/inquiry/setPatientInfo", data);


// 获取复诊预约信息
export const getOrderInfo = data => get("/api/inquiry/getOrderInfo", data)

// 设置复诊时间
export const setOrderTime = data => post('/api/inquiry/setOrderTime', data)

// 获取财务日志
export const getFinancelist = data => get('/api/finance/getList', data)

// 添加问诊处方单
export const inquiryAdd = data => post('/api/prescription/inquiryAdd', data)

// 获取自己添加的处方单、处方图片列表 (包括各种状态，)
export const getPrescriptionList = data => post('/api/prescription/getList',  data)

// 获取药师医生审核过的处方列表
export const getDoctorAuditList = data => get("/api/prescription/getDoctorAuditList", data);

// 获取待审核处方列表
export const getWaitingAuditList = data => get("/api/prescription/getWaitingAuditList", data)

// 处方单详情
export const getInfo = data => get("/api/prescription/getInfo", data)

// 审核处方单
export const audit = data => post('/api/prescription/audit', data);

// 获取所有药品分类
export const getAllCate = data => post("api/Goods_category/getAllCate", data);

//  获取 分类或搜索的商品列表
export const getCateIndex = data => get("api/Goods/index", data);

//  医生获取问诊用户档案列表
export const getPatientList = data => get("/api/user_patient/getList", data);

//  医生获取我的评价
export const getCommentList = data => get("/api/doctor/getCommentList", data)

//  获取医生问诊列表
export const getInquiryList = data => get("/api/doctor/getInquiryList", data)

//  获取问诊列表
export const getList = data => get("/api/inquiry/getList", data)

// 获取问诊详情
export const getInquiryInfo = data => get("/api/inquiry/getInfo", data)

// 药师更新图片处方单
export const picAdd = data => post("/api/prescription/updatePicPrescription", data);

export const deleteBank = data => post("/api/user_bank/delete", data) 

// 获取在线状态。
export const getDutyStatus = data => get("/api/doctor/getDutyStatus", data);
//获取上报不良信息列表选择项
export const getBedInfor = data => get("/api/data_dic/getList", data);
// 提交不良信息
export const subBedInfor = data => post("/api/report/add", data);
//获取问诊列表  
export const getNumberList = data => get("/api/report/getInquiryList", data);
//获取上报列表
export const getBadList = data => get("/api/report/getList", data);
//获取上报列表详情 
export const getBadInfo = data => get("/api/report/getInfo", data);
//获取病例详情
export const getcaseInfo = data => get("/api/inquiry/getInfo", data);
//获取疾病别表 
export const getDiseaseList = data => get("/api/disease/getlist", data);




